import banner from './assets/racing.mov'
import poster from './assets/jdmPoster.jpeg'
import { useLanguage } from '../../LanguageContext'

const JDMCars = () => {

    const { t } = useLanguage()

    return (
        <div>
            <div className="backgroundMask w-screen h-screen relative overflow-hidden">
                <video autoPlay loop muted
                    preload="auto"
                    poster={poster}
                    className="fixed z-[-2] top-0 bottom-0 h-screen w-screen object-cover bg-cover bg-no-repeat" >
                    <source src={banner} type="video/mp4"/>
                        Your browser does not support the video tag.
                </video>
                {/* <img src={banner} className="fixed z-[-2] top-0 bottom-0 h-screen w-auto object-cover bg-cover bg-no-repeat" alt="banner"/> */}
            
                {/* title */}
                <div className={
                        `relative uppercase text-3xl sm:text-6xl md:text-8xl text-white font-reina 
                        text-center top-1/2`
                    }>
                        {t('racing.title')}
                </div>
                <div className={
                        `relative uppercase text-md sm:text-xl md:text-2xl text-white font-reina 
                        text-center top-1/2`
                    }>
                        {t('racing.subtitle')}
                </div>
            </div>

            {/* introduction */}
            <div className='bg-palateBlue py-32 px-10 md:px-20 font-gilmerLight text-center
                text-md md:text-xl space-y-16 text-white'>
                <p>{t('racing.contents.intro')}</p>
                    
                <div>
                    <h1 className='font-reina text-3xl'>{t('racing.contents.objective.title')}</h1>
                    <p>
                        {t('racing.contents.objective.content')}
                    </p>
                </div>

                <div>
                    <h1 className='font-reina text-3xl'>{t('racing.contents.services.title')}</h1>
                    <p>
                        {t('racing.contents.services.1')}<br/>
                        {t('racing.contents.services.2')}
                    </p>
                </div>

                <div>
                    <h1 className='font-reina text-3xl'>{t('racing.contents.goals.title')}</h1>
                    <p>
                        {t('racing.contents.goals.1')}<br/>
                        {t('racing.contents.goals.2')}<br/>
                        {t('racing.contents.goals.3')}<br/>
                        {t('racing.contents.goals.4')}<br/>
                    </p>
                </div>
            </div>
         
        </div>
    )
}

export default JDMCars;