import { useLanguage } from "../LanguageContext"

const Footer = () => {
    const { t } = useLanguage()
    
    return (
        // <div className="backgroundMask w-screen relative overflow-hidden font-gilmerLight">
        <div className="bg-black w-screen relative overflow-hidden font-gilmerLight">
            <div className="pb-[100px]">
                <hr className="bh-white w-[90%] mx-auto my-[80px]"/>
                <div className="flex px-[5%] flex-col md:flex-row justify-around">
                    <div className="flex flex-col md:w-1/3 lg:w-1/4 items-center md:items-start">
                        <p className="uppercase text-white my-2 font-gilmerRegular">
                            {t('footer.question')}
                        </p>
                        <a className="uppercase text-white my-2 font-lignt hover:pointer hover:underline"
                            href="mailto:lincoln@palatetrading.com">
                            {t('footer.email')}
                        </a>
                        <p className="uppercase text-white my-2 font-light text-center md:text-left">
                            303 Twin Dolphin Drive<br/>Suite 600 - #6001 Redwood City, California, 94065</p>
                    </div>
                    <div className="flex flex-col md:w-1/3 lg:w-1/4 px-[2%] items-center">
                        <p className="uppercase text-white my-2 font-gilmerRegular">Social Media</p>
                        <p className="uppercase text-white my-2 font-light">-</p>
                        <a className="uppercase text-white my-1 font-lignt hover:pointer hover:underline"
                            href="https://www.instagram.com/palatetrading/"
                            target="blank" rel="noreferrer">
                            instagram
                        </a>
                        <a className="uppercase text-white my-1 font-lignt hover:pointer hover:underline"
                            href="https://www.facebook.com/profile.php?id=6155176495346"
                            target="blank" rel="noreferrer">
                            facebook
                        </a>
                    </div>
                    <div className="hidden lg:flex relative flex-col w-1/2 px-[2%]">
                        {/* <p className="text-white my-2 font-light text-md">
                            Join our pilgrimage— seeking quality, truth and accountability in coffee. Sign-up on our email list below to join the
                            Onyx Coffee Society. This will give you “early access” to special offerings, discount codes, brew videos and
                            secrets…shhhh!…
                        </p> */}
                        
                        <p className="absolute bottom-0 right-0 text-white my-2 font-light text-[12px]">
                            @PALATE TRADING COMPANY LLC. ALL RIGHTS RESERVED
                        </p>
                    </div>
                </div>
                <p className="relative text-center pt-10 text-white my-2 font-light text-[12px] lg:hidden">
                        @PALATE TRADING COMPANY LLC. ALL RIGHTS RESERVED
                 </p>
            </div>
        </div>
    )
}
export default Footer
