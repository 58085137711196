import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';

import 'tailwindcss/tailwind.css';
import './i18n'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
