import banner from "./images/intro.mp4"
import poster from "./images/introPoster.jpeg"
import title_img from "../../assets/MAIN.png"
import "./LandingPage.css"
import Bussinesses from "./Businesses"
import { useLanguage } from "../../LanguageContext"

const LandingPage = () => {

    const { t } = useLanguage()

    let values = t('main.values.content', { returnObjects: true })
    values = values.map((content, i) => (
        <p className="text-md md:text-xl text-center font-gilmerLight mt-2"
            key={i}>
            {content}
        </p>
    ))
    
    return (
        <div>         
            <div className="backgroundMask w-screen h-screen relative overflow-hidden">
                <video autoPlay loop muted
                    preload="auto"
                    poster={poster}
                    className="fixed z-[-2] top-0 bottom-0 h-screen w-screen object-cover bg-cover bg-no-repeat" >
                    <source src={banner} type="video/mp4"/>
                        Your browser does not support the video tag.
                </video>
                {/* <img src={banner} className="fixed z-[-2] top-0 bottom-0 h-screen w-auto object-cover bg-cover bg-no-repeat" alt="banner"/> */}
            </div>
            
            <div className="sticky h-80 w-screen bottom-1/4 items-center flex xxl:bottom-1/3">
                <img src={title_img} className="mx-auto w-1/2 lg:w-1/3 xxl:w-1/4" alt="title"/>
            </div>
            <div className="mygrid grid absolute top-0 left-0 w-screen overflow-clip">
                <div className="h-screen w-screen"></div>
                <div className="sticky h-80 w-screen bottom-1/4 items-center flex xxl:bottom-1/3">
                    <img src={title_img} className="logo mx-auto w-1/2 lg:w-1/3 xxl:w-1/4" alt="title"/>
                </div>
            </div>
            

            <div className="introduction bg-palateGreen mt-[-320px]">
                <div className="mx-auto pt-80 px-10 pb-20">
                    <h1 className="text-md md:text-xl text-center font-gilmerLight">
                        {t('main.description')}
                    </h1>
                    <h1 className="text-xl md:text-3xl text-center font-reina mt-12">
                        {t('main.objective.title')}
                    </h1>
                    <p className="text-md md:text-xl text-center font-gilmerLight mt-2">
                        {t('main.objective.content')}
                    </p>
                    <h1 className="text-xl md:text-3xl text-center font-reina mt-12">
                        {t('main.values.title')}
                    </h1>
                    {values}
                </div>
            </div>

            <Bussinesses />
        </div>
    ) 
}

export default LandingPage;
