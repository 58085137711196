import { Link } from "react-router-dom"
import './LandingPage.css'
import { useLanguage } from "../../LanguageContext"

const Bussiness = (props) => {

    const { t } = useLanguage()
    
    const handleNavClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <div className="relative h-screen w-screen overflow-hidden">
            <div className="bg-black absolute top-0 h-screen w-screen opacity-30 z-10"></div>
            <img src={props.bg_src} 
                className="absolute h-screen w-screen object-cover bg-cover bg-no-repeat z-0" alt="bg"/>

            <div className={
                `absolute top-[20%] z-10 px-6 w-screen
                ${props.left_layout? 'lg:left-[4%]': 'lg:right-[4%] flex flex-col items-end'}`}
            >
                {/* title */}
                <div className={
                    `uppercase ${props.left_layout? 'text-left': 'text-right'}
                    text-5xl sm:text-6xl md:text-8xl text-white font-reina`
                }>
                    {t('header.palate')}
                </div>
                <div className={
                    `uppercase ${props.left_layout? 'text-left': 'text-right'}
                    text-5xl sm:text-6xl md:text-8xl text-white font-reina`
                }>
                    {props.title}
                </div>

                {/* descriptions */}
                <div className={
                    `${props.left_layout? 'text-left': 'text-right'}
                    text-md md:w-1/2 xl:w-1/3 text-white py-[12%] md:py-[6%] lg:py-[4%] font-gilmerLight`
                }>
                    {props.description}
                </div>

                <Link to={props.to} onClick={handleNavClick}
                    className="bg-black text-white uppercase hover:bg-white hover:text-black
                    py-[12px] px-[20px] text-[14px] hover:pointer font-gilmerRegular">
                    {props.button_text}
                </Link>
            </div>
        </div>
    )
}

export default Bussiness
