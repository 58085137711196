import { useLanguage } from "../../LanguageContext";

const WhiskyInfo = (props) => {

    const { t } = useLanguage()

    return (
        <div className="relative h-screen w-screen overflow-hidden">
            <div className="bg-black absolute top-0 h-screen w-screen opacity-40 z-10"></div>
            <img src={props.bg_src} 
                className="absolute h-screen w-screen object-cover bg-cover bg-no-repeat z-0" alt="bg"/>

            <div className={
                `absolute z-10 w-screen flex flex-col md:flex-row items-center px-20`
            }>
                <div className="flex flex-col w-screen md:flex-1 text-white">
                    <div className="py-10 pl-10 sm:py-20 sm:pl-20">
                        {/* title */}
                        <div className={
                            `uppercase text-5xl sm:text-6xl md:text-8xl font-reina`
                        }>
                            {props.title}
                        </div>
                        <div className={`hidden sm:block uppercase text-3xl font-reina`}>
                            {props.subtitle}
                        </div>

                        {/* size */}
                        <div className={`uppercase pt-2 font-gilmerRegular`}>
                            {props.size}
                        </div>

                        {/* descriptions */}
                        <div className={
                            `pt-4 font-gilmerRegular leading-tigh text-justify w-5/6`
                        }>
                            {props.description}
                        </div>

                        {/* tasting notes */}
                        <div className="flex flex-col sm:flex-row sm:space-x-4 pt-2">
                            <div className="uppercase font-gilmerRegular sm:w-20">Tasting notes</div>
                            <div className="flex flex-col">
                                <div className="flex flex-row space-x-4">
                                    <div className="capitalize font-gilmerRegular w-20">
                                        {t('whisky.kaichi.color.title')}
                                    </div>
                                    <div className="capitalize font-gilmerRegular w-40 md:w-80">
                                        {props.tasting?.color}<br/>
                                    </div>
                                </div>
                                <div className="flex flex-row space-x-4">
                                    <div className="capitalize font-gilmerRegular w-20">
                                        {t('whisky.kaichi.nose.title')}
                                    </div>
                                    <div className="capitalize font-gilmerRegular w-40 md:w-80">
                                        {props.tasting?.nose}<br/>
                                    </div>
                                </div>
                                <div className="flex flex-row space-x-4">
                                    <div className="capitalize font-gilmerRegular w-20">
                                        {t('whisky.kaichi.palate.title')}
                                    </div>
                                    <div className="capitalize font-gilmerRegular w-40 md:w-80">
                                        {props.tasting?.palate}<br/>
                                    </div>
                                </div>
                                <div className="flex flex-row space-x-4">
                                    <div className="capitalize font-gilmerRegular w-20">
                                        {t('whisky.kaichi.finish.title')}
                                    </div>
                                    <div className="capitalize font-gilmerRegular w-80">
                                        {props.tasting?.finish}<br/>
                                    </div>
                                </div>
                            </div>  
                        </div>

                        {/* winners */}
                        <div className="flex flex-row space-x-4 items-center pt-4">
                            <img src={props.winner} 
                                className="w-[80px] h-[80px]" alt="pic"/>
                            <img src={props.winner2} 
                                className="w-[90px]" alt="pic"/>
                        </div>
                    </div>
                </div>

                <div className="hidden lg:flex lg:flex-1 items-center justify-center md:h-screen">
                    <img src={props.picture} 
                        className="md:max-h-screen min-w-[400px] md:pr-20 md:py-20" alt="pic"/>
                </div>
            </div>
            
            
        </div>
    )

}

export default WhiskyInfo;
