import logo from '../../assets/MAIN.png'
import Card from './Card'
import ReactDOM from 'react-dom'
import { useLanguage } from '../../LanguageContext'

const AgeConfirmModal = (props) => {

    const { t } = useLanguage()

    const handleAgree = () => {
        localStorage.setItem('isAgree', true)
        props.handleClose()
    }

    const handleDisAgree = () => {
        window.location.href = '/'
    }

    return (
        <>
            <div className='fixed top-0 left-0 w-screen h-screen z-30 bg-black bg-opacity-60'></div>        
            <Card className='fixed top-[20vh] left-[10%] w-[80%] overflow-hidden z-40
                         flex flex-col items-center text-center font-gilmerRegular p-10 md:p-12 lg:p-20'>
                <img src={logo} alt='logo' className='w-4/5 md:w-1/3'/>
                <p className='pt-8'>
                    Welcome to Palate Whiskies. <br/>
                    {t('ageConfirm.p')}
                </p>
                <div className='flex flex-col space-y-6 md:flex-row md:space-x-6 md:space-y-0 py-8'>
                    <button onClick={handleAgree}
                        className="bg-black text-white uppercase hover:bg-white hover:text-black
                        w-[140px] py-[12px] px-[20px] text-[14px] hover:pointer font-gilmerRegular">
                        {t('ageConfirm.agree')}
                    </button>
                    <button onClick={handleDisAgree}
                        className="bg-black text-white uppercase hover:bg-white hover:text-black
                        w-[140px] py-[12px] px-[20px] text-[14px] hover:pointer font-gilmerRegular">
                        {t('ageConfirm.disagree')}
                    </button>
                </div>
            </Card>
        </>
    )
}

const AgeCondirm = (props) => {

    return (
        <>
            {ReactDOM.createPortal(
                <AgeConfirmModal handleClose={props.handleClose}/>,
                document.getElementById('ageConfirmModal')
            )}
        </>
    )
}

export default AgeCondirm