import Header from './components/Header'
import Footer from './components/Footer'
import LandingPage from './components/landing/LandingPage'
import Whisky from './components/whisky/Whisky'
import Houses from './components/houses/Houses'
import JDMCars from './components/JDMCars/JDMCars'
import Collectibles from './components/collectibles/Collectibles'
import Contact from './components/contact/Contact'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import "./App.css"
import { LanguageProvider } from './LanguageContext'

function App() {

    return (
        <LanguageProvider>
            <Router>
                <Header/>
                
                {/* Route configuration */}
                <Routes>
                    <Route index path="/" element={<LandingPage />} />
                    <Route path="/about" element={<LandingPage />} />
                    <Route path="/whiskies" element={<Whisky />} />
                    <Route path="/houses" element={<Houses />} />
                    <Route path="/racing" element={<JDMCars />} />
                    <Route path="/collectibles" element={<Collectibles />} />
                    <Route path="/contact" element={<Contact />} />
                </Routes>

                <Footer />
            </Router>
        </LanguageProvider>
    )
}

export default App;
