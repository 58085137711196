
import { useState, useEffect, Suspense } from "react"
import { Link, useLocation } from "react-router-dom"
import logo from "../assets/globe.png"
import "./Header.css"
import { useLanguage } from "../LanguageContext"
import i18next from "i18next"
import us_flag from "../assets/us_flag.png"
import jp_flag from "../assets/jp_flag.png"

const Header = () => {
    const { t, changeLanguage } = useLanguage()
    
    const handleLanguageSwitch = () => {
        const newLanguage = i18next.language === 'en' ? 'jap': 'en'
        changeLanguage(newLanguage)
    }

    let navs = {
        'About': '/',
        'Whiskies': '/whiskies',
        'Racing': '/racing',
        'Houses': '/houses',
        // 'Collectibles': '/collectibles',
        // 'Contact us': '/contact',
    }

    // mobile header open/close
    const [isOpen, setIsOpen] = useState(false)
    const toggleNav = () => {
        setIsOpen(!isOpen)
    }

    // screen scroll effects
    const scrollThreshold = 300 
    const [isScrolled, setIsScrolled] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > scrollThreshold) {
                setIsScrolled(true)
            } else {
                setIsScrolled(false)
            }
        }

        window.addEventListener('scroll', handleScroll)

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const handleNavClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setIsOpen(false)
    }

    const location = useLocation()
    navs = Object.entries(navs).map(([label, path]) => (
        <Link className="group"
            key={label} to={path} onClick={handleNavClick} >
            <div className={`mx-2 group-hover:border-b group-hover:border-blue-50 font-reina
                             ${location.pathname === path? 'border-b border-blue-50': ''}`} >
                {t(`header.${label}`)}
            </div>
        </Link>
    ))

    return (
        <div>
            <nav id="header" className={`flex px-16 items-center text-white justify-center
                    fixed top-0 h-[74px] w-screen ${isScrolled ? 'bg-black': 'bg-transparent'} transition-all duration-300 ease-in-out z-50`}>
                
                <Link to='/' onClick={handleNavClick}>
                    <img className="logo fixed top-4 left-4 md:left-8 lg:left-12 h-8 md:h-12" src={logo} alt="logo"/>
                </Link>
                <div className="hidden h-10 lg:flex lg:space-x-20 items-center ">
                    {navs}
                </div>
                
                {/* hamburger button */}
                <div className="lg:hidden fixed top-6 right-6">
                    <button id="menu-btn" type="button" 
                        className={`z-40 block hamburger lg:hidden focus:outline-none ${isOpen? 'open': ''}`}
                        onClick={toggleNav} >
                        <span className="hamburger-top"></span>
                        <span className="hamburger-middle"></span>
                        <span className="hamburger-bottom"></span>
                    </button>
                </div>
            </nav>

            {/* mobile menue */}
            <div id="menu" className={`fixed top-0 min-h-screen w-screen flex-col self-end ${isOpen? 'flex': 'hidden'}
                    py-1 pt-40 pl-12 space-y-3 text-lg text-white uppercase bg-black bg-opacity-80 z-40`}
                onClick={toggleNav}>
                {navs}
            </div>

            <button className="fixed top-5 right-20 lg:right-12 z-50 flex flex-row items-center
                    text-white font-gilmerLight px-4 text-sm"
                    onClick={handleLanguageSwitch}>
                <Suspense fallback={<></>}>
                    {i18next.language && (
                        <>
                            <img src={t('lng') === 'English' ? jp_flag: us_flag } className="w-6 mr-2" alt="flag"/>
                            {t('lng') === 'English'? "日本語": "English"}
                        </>
                    )}
                </Suspense>
            </button>
        </div>
    )
}

export default Header