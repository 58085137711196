import WhiskyInfo from './WhiskyInfo'
import KaichiBg from './images/KaichiBg.jpg'
import SorachiBg from './images/SorachiBg.jpg'
import kaichi from './images/KaichiBottle.png'
import sorachi from './images/SorachiBottle.png'
import banner from './images/seigantoji.mov'
import poster from './images/whiskyPoster.jpeg'
import gold from './images/24gold.png'
import silver_winner from './images/silver_winner.png'
import silver from './images/24silver.png'
import AgeCondirm from './AgeConfirm'
import { useEffect, useState } from 'react'
import { useLanguage } from "../../LanguageContext"

const Whisky = () => {

    const { t } = useLanguage()

    let whiskies = [
        [
            KaichiBg,
            kaichi,
            "Kaichi",
            "blended malt whisky",
            "700 ml 43% Alc./Vol (86 PROOF)",
            {
                "color": t('whisky.kaichi.color.content'),
                "nose": t('whisky.kaichi.nose.content'),
                "palate": t('whisky.kaichi.palate.content'),
                "finish": t('whisky.kaichi.finish.content'),
            },
            t('whisky.kaichi.intro'),
             gold,
             silver_winner,
        ],
        [
            SorachiBg,
            sorachi,
            "Sorachi",
            "blended malt whisky",
            "700 ml 40% Alc./Vol (80 PROOF)",
            {
                "color": t('whisky.sorachi.color.content'),
                "nose": t('whisky.sorachi.nose.content'),
                "palate": t('whisky.sorachi.palate.content'),
                "finish": t('whisky.sorachi.finish.content'),
            },
            t('whisky.sorachi.intro'),
            silver,
            silver_winner,
        ],
    ]

    whiskies = whiskies.map((contents, i) => (
        <WhiskyInfo
            key = {i}
            bg_src = {contents[0]}
            picture = {contents[1]}
            title = {contents[2]}
            subtitle = {contents[3]}
            size = {contents[4]}
            tasting = {contents[5]}
            description = {contents[6]}
            winner = {contents[7]}
            winner2 = {contents[8]}
        />
    ))

    const [isModalOpen, setModelOpen] = useState(true)
    const handleModalClose = () => {
        setModelOpen(false)
    }
    useEffect(() => {
       const agree = localStorage.getItem('isAgree')
       setModelOpen(agree? false: true)
    }, [isModalOpen])
    
    return (
        <div>
            {isModalOpen && <AgeCondirm handleClose={handleModalClose}/>}
            <div className="backgroundMask w-screen h-screen relative overflow-hidden">
                <video autoPlay loop muted
                    preload="auto"
                    poster={poster}
                    className="fixed z-[-2] top-0 bottom-0 h-screen w-screen object-cover bg-cover bg-no-repeat" >
                    <source src={banner} type="video/mp4"/>
                        Your browser does not support the video tag.
                </video>
                {/* <img src={banner} className="fixed z-[-2] top-0 bottom-0 h-screen w-auto object-cover bg-cover bg-no-repeat" alt="banner"/> */}
            
                {/* title */}
                <div className={
                        `relative uppercase text-3xl sm:text-6xl md:text-8xl text-white font-reina 
                        text-center top-1/2`
                    }>
                        {t('whisky.title')}
                </div>
            </div>

            {whiskies}

            {/* distillery & water */}
            <div className="backgroundMask w-screen font-gilmerLight">
                <div className='py-40 px-20 flex flex-col text-white space-between text-justify space-y-20'>
                    <div className='mx-auto md:w-1/2'>
                        <h1 className='text-center font-reina text-3xl xxl:text-4xl'>{t('whisky.distillery.title')}</h1>
                        <p className='pt-6 font-gilmerLight text-md md:text-xl xxl:text-2xl'>
                            {t('whisky.distillery.content')}
                        </p>
                    </div>
                    <div className='mx-auto md:w-1/2'>
                        <h1 className='text-center font-reina text-3xl xxl:text-4xl'>{t('whisky.water.title')}</h1>
                        <p className='pt-6 font-gilmerLight text-md md:text-xl xxl:text-2xl'>
                            {t('whisky.water.content')}
                        </p>
                    </div>
                    <div className='mx-auto md:w-1/2'>
                        <h1 className='text-center font-reina text-3xl xxl:text-4xl'>{t('whisky.casks.title')}</h1>
                        <p className='pt-6 font-gilmerLight text-md md:text-xl xxl:text-2xl'>
                            {t('whisky.casks.mizunara')}<br/><br/>
                            {t('whisky.casks.hinoki')}
                        </p>
                    </div>
                    <p className='text-center pt-4 font-gilmerLight text-md xxl:text-lg'>
                       - wakayama falls -
                    </p>
                </div>
            </div>

            {/* how to drink */}
            <div className="bg-palateGreen">
                <div className='py-20 px-6 md:py-28 md:px-20 md:text-justify'>
                    <h1 className='font-reina text-4xl'>How to Drink</h1>
                    <hr className='my-6 border-black'/>
                    <h2 className='font-reina text-2xl pt-6'>
                        {t('whisky.drink.kaichi.title')}
                    </h2>
                    <p className='font-gilmerLight md:text-lg'>
                        {t('whisky.drink.kaichi.1')}<br/>
                        {t('whisky.drink.kaichi.2')}<br/>
                        {t('whisky.drink.kaichi.3')}<br/>
                        {t('whisky.drink.kaichi.4')}<br/>
                        {t('whisky.drink.kaichi.5')}
                    </p>

                    <h2 className='font-reina text-2xl pt-6'>
                        {t('whisky.drink.sorachi.title')}
                    </h2>
                    <p className='font-gilmerLight md:text-lg'>
                        {t('whisky.drink.sorachi.1')}<br/>
                        {t('whisky.drink.sorachi.2')}<br/>
                        {t('whisky.drink.sorachi.3')}<br/>
                        {t('whisky.drink.sorachi.4')}<br/>
                        {t('whisky.drink.sorachi.5')}<br/>
                        {t('whisky.drink.sorachi.6')}
                    </p>

                    <h2 className='font-reina text-2xl pt-6'>
                        {t('whisky.drink.twice.title')}
                    </h2>
                    <p className='font-gilmerLight md:text-lg'>
                        {t('whisky.drink.twice.1')}<br/>
                        {t('whisky.drink.twice.2')}<br/>
                        {t('whisky.drink.twice.3')}<br/>
                        {t('whisky.drink.twice.4')}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Whisky;
