import React, { createContext, useContext } from 'react'
import { Suspense } from 'react'
import { useTranslation } from 'react-i18next'

const LanguageContext = createContext()

export const useLanguage = () => {
    return useContext(LanguageContext)
}

export const LanguageProvider = ({ children }) => {
    // t - translation, i18n - switch the languate
    const { t, i18n } = useTranslation()

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }

    return (
        <Suspense>
            <LanguageContext.Provider value={{ t, changeLanguage }}>
                {children}
            </LanguageContext.Provider>
        </Suspense>
    )
}
