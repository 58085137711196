import { useEffect } from "react"
import Bussiness from "./Business"
import whisky_bg from "./images/whisky.jpeg"
import house_bg from "./images/living.jpg"
import jdm_bg from "./images/jdm.jpeg"
// import collectible_bg from "./images/collectible.png"
import { useLanguage } from "../../LanguageContext"

const Bussinesses = () => {

    const { t } = useLanguage()

    useEffect(() => {
        
    }, [])

    let myBussinesses = [
        [
            whisky_bg,
            t('header.Whiskies'),
            t('whisky.intro'),
            t('buttonText'),
            true,
            '/whiskies',
        ],
        [
            jdm_bg,
            t('header.Racing'),
            t('racing.intro'),
            t('buttonText'),
            true,
            '/racing',
        ],
        [   
            house_bg,
            t('header.Houses'),
            t('houses.intro'),
            t('buttonText'),
            true,
            '/houses',
        ],
        // [
        //     collectible_bg,
        //     'Collectibles',
        //     `Trading Cards and Figures collectibles evoke a type of excitement in its community. Whether it be the 
        //     characters, the artwork, or the camaraderie of sharing these with friends, the accessibility to these would
        //     leave memories lasting a lifetime.`,
        //     'Explore More',
        //     false,
        //     '/collectibles',
        // ],
    ]
    
    myBussinesses = myBussinesses.map((contents, i) => (
        <Bussiness
            key = {i}
            bg_src = {contents[0]}
            title = {contents[1]}
            description = {contents[2]}
            button_text = {contents[3]}
            left_layout = {contents[4]}
            to = {contents[5]}
        />
    ))

    return myBussinesses
}

export default Bussinesses